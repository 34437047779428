













import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class TotalScore extends Vue {
  @Prop({ type: Number, default: 0 }) readonly score!: number
  @Prop({ type: Number, default: 1 }) readonly position!: number

  get totalScore (): number {
    return 100 * this.position
  }
}




























































import { Component, Vue, Prop } from 'vue-property-decorator'
import PoweredBy from '../components/PoweredBy.vue'

@Component({
  components: {
    PoweredBy
  }
})
export default class AnswerView extends Vue {
  @Prop({ type: Boolean, default: false }) readonly preview!: boolean;

  async mounted () {
    const question = this.$store.state.question

    if (!question) {
      this.$router.replace({ name: 'question' })
    }
  }

  get hasAnswerFile () {
    return this.answerFile !== undefined && this.answerFile !== null
  }

  get answerFileType () {
    const answerFile = this.answerFile

    if (answerFile) {
      const fileType = answerFile.split('.').pop()

      if (fileType === 'mp4') {
        return 'video'
      }
    }

    return 'image'
  }

  get answerFile () {
    const prefix = this.isCorrect
      ? 'correct_background_video'
      : 'wrong_background_video'

    const { [prefix]: answer_file } = this.$store.state.question

    return answer_file
  }

  get explanation () {
    const field = this.isCorrect ? 'correct_text' : 'wrong_text'

    return this.$store.state.question[field] ?? ''
  }

  get answerTitle () {
    return this.isCorrect ? this.$t('answer.correct') : this.$t('answer.wrong')
  }

  get icon () {
    return this.isCorrect ? 'fa-check' : 'fa-times'
  }

  get isCorrect (): boolean {
    return this.$store.state.correct
  }

  get score (): number {
    return this.$store.state.score
  }

  get selectedLocale () {
    return this.$root.$i18n.locale
  }

  nextQuestion () {
    if (!this.preview) {
      this.$router.replace({ name: 'question' })
    } else {
      location.reload()
    }
  }
}

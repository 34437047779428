














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class UserProgress extends Vue {
  @Prop({ type: Number, default: 0 }) readonly percentage!: number

  activePercentage = 0

  mounted () {
    setTimeout(() => {
      this.activePercentage = this.percentage
    }, 1000)
  }

  get barStyle () {
    return {
      width: `${this.activePercentage}%`
    }
  }

  get playerStyle () {
    return {
      left: `${this.activePercentage}%`
    }
  }
}

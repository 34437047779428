























import { Component, Vue } from 'vue-property-decorator'
import UserProgress from '@/components/UserProgress.vue'
import TotalScore from '@/components/TotalScore.vue'

@Component({
  components: {
    UserProgress,
    TotalScore
  }
})
export default class ResultsView extends Vue {
  async mounted () {
    // const bgVideo = 'https://sanquinquizatwork.s3.amazonaws.com/quiz/correct1.mp4'

    // this.$store.commit('setBackgroundVideo', bgVideo)

    this.$store.dispatch('getNextQuestion')
  }

  beforeDestroy () {
    this.$store.commit('setBackgroundVideo', '')
  }

  get username (): string {
    return this.$store.state.username
  }

  get score (): number {
    return this.$store.state.score
  }

  get position (): number {
    return this.$store.state.position
  }

  get betterThan (): number {
    return this.$store.state.better
  }

  get hasClickoutLink (): boolean {
    return this.clickoutLink !== null
  }

  get clickoutLink (): string|null {
    return this.$store.state.clickout_link
  }

  get shareLink (): string {
    let spot = window.localStorage.getItem('slug')

    if (!spot || spot === '' || spot === 'null') spot = 'default'

    const quizLink = `https://sanquinatworkquiz.nl/${spot}`
    // const quizLink = `${window.location.protocol}//${window.location.hostname}/spot/${spot}`

    const text = 'Hoi ik heb net de Sanquin at Work Quiz gespeeld, speel je ook mee?\n\nDoneer bloed en red levens!'

    const urlEncodedText = encodeURIComponent(`${text}\n\n${quizLink}`)

    return `https://wa.me/?text=${urlEncodedText}`
  }
}
